import React from "react"
import { Link, graphql } from 'gatsby'
import { Helmet } from "react-helmet"

import SEO from "../components/seo"


import home from '../scss/home.module.scss'

import Footer from "../components/footer"
import Logo from '../images/lgo-stand.inline.svg'
import SRlogo from '../images/lgo-sr.inline.svg'
import Arrow from '../images/ico-arrow-down.inline.svg'

export const data = graphql`
    query {
      intro: file(name: {eq: "intro"}) {
        id
        childMarkdownRemark {
          frontmatter {
            title
            author
          }
          html
        }
      }
      lower: file(name: {eq: "lower"}) {
        id
        childMarkdownRemark {
          frontmatter {
            title
            author
          }
          html
        }
      }
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
`
const IndexPage = (props) => (
  <>
    <SEO title={props.data.site.siteMetadata.title} />
    <Helmet bodyAttributes={{id: 'home'}}  />
    <div className={home.wrapper}>
      <section className={`centered ${home.firstCol}`}>
        <div className={home.logoContainer}>
          <Logo className={home.logo} />
          <p className={home.launched}>An initiative launched by <a href="https://studioresonate.com" aria-label="Studio Resonate" target="_blank" className="srlogohpcontainer" rel="noreferrer"><SRlogo className={`srlogohp ${home.srlogo}`} /></a></p>

        </div>
      </section>
      <section className={home.secondCol}>
        <div className={home.secondColWrapper}>

          <div
            className={home.upper}
            dangerouslySetInnerHTML={{ __html: props.data.intro.childMarkdownRemark.html }}
          />

            <ul className="cta stack">
              <li id="homeform" className={home.primary}>
                <button id="homeformlink" className="button primary" data-modal-open="standwithus">Stand with us</button>
              </li>
              <li id="about"><Link id="aboutlink" className="button secondary" to="/about">About The Stand For Sonic Diversity</Link></li>
            </ul>

        </div>

      </section>
      <a href="#lower" role="button" aria-label="See more" title="See more"><Arrow className={`bounce ${home.lowerarrow}`} /></a>
    </div>


    <div className={home.wrapperLower}>

      <section className={home.column}>
          <div
            className={home.lower}
            id="lower"
            dangerouslySetInnerHTML={{ __html: props.data.lower.childMarkdownRemark.html }}
          />
      </section>

      <footer className={home.footer}>
        <Footer />
      </footer>


    </div>
  </>
)

export default IndexPage
